riot.mount('*')

;(function($){



$(() => {

  if(!feature.touch){
    $('a[href^="#"], .js-anchor-scroll').on('click', e => {
      e.preventDefault()
      let headerHeight = 0
      let $target = $(e.currentTarget.attributes.href.value)
      console.log($target)
      let targetOffset = $target.offset().top
      $("html,body").animate({
        scrollTop: targetOffset - headerHeight - 10
      }, 300)
      return false
    })
  }
  var ww = $(window).width();
  //window.alert(ww);

  if(ww < 840){
    //スマホ幅の時
    $('.c-header-nav-layout').addClass('c-header-nav-layout--sp');
    $('.c-header-nav-layout--sp').css('display','none');
    $('.js-footer-link--corp_info_link').css('pointer-events','none');
    $('.js-footer-link--service_link').css('pointer-events','none');
    $('.js-footer-link--recruit_link').css('pointer-events','none');

  }else{
    //PCの時
    $('.c-header-nav-layout').removeClass('c-header-nav-layout--sp');
  }
})

$(window).resize(function () {
  var ww = $(window).width();
  //window.alert(ww);

  if(ww < 840){
    //スマホ幅の時
    $('.c-header-nav-layout').addClass('c-header-nav-layout--sp');
    $('.c-header-nav-layout--sp').css('display','none');
    $('.js-footer-link--corp_info_link').css('pointer-events','none');
    $('.js-footer-link--service_link').css('pointer-events','none');
    $('.js-footer-link--recruit_link').css('pointer-events','none');

  }else{
    //PCの時
    $('.c-header-nav-layout').removeClass('c-header-nav-layout--sp');
    $('.c-header-nav-layout').css('display','flex');

  }
});

$('.js-header-nav--open_btn').click(function (){//

  $('.c-header-nav-layout--sp').toggle(200);


});
$('.js-header-nav--close_btn').click(function (){//

  $('.c-header-nav-layout--sp').toggle(200);


});

$('.js-footer-link--corp_info').click(function (){//
  var ww = $(window).width();
  if(ww < 840){
    //スマホ幅の時
    $('.js-footer-link--corp_info_link').css('pointer-events','auto');
    $('.js-footer-link--service_link').css('pointer-events','none');
    $('.js-footer-link--recruit_link').css('pointer-events','none');
    $('.js-footer-link--service_child').css('display','none');
    $('.js-footer-link--recruit_child').css('display','none');
    $('.js-footer-link--corp_info_child').toggle(200);
  }else{
    $('.js-footer-link--service_child').css('display','block');
    $('.js-footer-link--recruit_child').css('display','block');
    $('.js-footer-link--corp_info_child').css('display','block');
    $('.js-footer-link--corp_info_link').css('pointer-events','auto');
  }
});

$('.js-footer-link--service').click(function (){//
  var ww = $(window).width();
  if(ww < 840){
    //スマホ幅の時
    $('.js-footer-link--service_link').css('pointer-events','auto');
    $('.js-footer-link--corp_info_link').css('pointer-events','none');
    $('.js-footer-link--recruit_link').css('pointer-events','none');
    $('.js-footer-link--service_child').toggle(200);
    $('.js-footer-link--recruit_child').css('display','none');
    $('.js-footer-link--corp_info_child').css('display','none');
  }else{
    $('.js-footer-link--service_child').css('display','block');
    $('.js-footer-link--recruit_child').css('display','block');
    $('.js-footer-link--corp_info_child').css('display','block');
    $('.js-footer-link--corp_info_link').css('pointer-events','auto');
  }
});

$('.js-footer-link--recruit').click(function (){//
  var ww = $(window).width();
  if(ww < 840){
    //スマホ幅の時
    $('.js-footer-link--recruit_link').css('pointer-events','auto');
    $('.js-footer-link--service_link').css('pointer-events','none');
    $('.js-footer-link--corp_info_link').css('pointer-events','none');
    $('.js-footer-link--service_child').css('display','none');
    $('.js-footer-link--recruit_child').toggle(200);
    $('.js-footer-link--corp_info_child').css('display','none');
  }else{
    $('.js-footer-link--service_child').css('display','block');
    $('.js-footer-link--recruit_child').css('display','block');
    $('.js-footer-link--corp_info_child').css('display','block');
    $('.js-footer-link--corp_info_link').css('pointer-events','auto');
  }
});




$('.js-tab-heading').on('click', e => {
  $this = $(e.currentTarget)
  $parent = $this.parent()
  $parent2 = $parent.parent()

  $parent.children('.js-tab-heading').attr('aria-selected', 'false')
  $parent2.children('.js-tab').attr('aria-hidden', 'true')
  $this.attr('aria-selected', 'true')
  $tabNumber = $this.data('tab')

  console.log($tabNumber)

  $parent2.children(`.js-tab[data-tab='${ $tabNumber}']`).attr('aria-hidden', 'false')
})

})(jQuery)
